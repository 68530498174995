import CheckCircleFilled from '@ant-design/icons/lib/icons/CheckCircleFilled'
import CloseOutlined from '@ant-design/icons/lib/icons/CloseOutlined'
import { notification } from 'antd'
const notificationSuccess = (message: string, className?: string) =>
  notification.success({
    message: message,
    closeIcon: <CloseOutlined style={{ color: '#141921', fontSize: 16 }} />,
    icon: <CheckCircleFilled style={{ color: '#33950a', fontSize: 24 }} />,
    className: className
  })
export { notification, notificationSuccess }
